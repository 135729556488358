
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import LDDExplore from "@/components/driver-details/List.vue";
// import { themeName, demo } from "@/core/helpers/documentation";
import QuotesService from "@/core/services/car/QuotesService";
import CustomerService from "@/core/services/car/CustomerService";
// import LeadDriverDetailsListView from "@/components/partials/leaddriverdetails/Lead-DriverDetails-List.vue";
interface QuotesBodyParam {
  customer_id: number;
  lead_id: number;
}
export default defineComponent({
  name: "ai-explore",
  components: {
    LDDExplore,
    // QuotesAction,
    // LeadDriverDetailsListView
  },
  props: {},
  data() {
    return {
      //  emirates:['Abu Dhabi','Ajman','Dubai','Fujairah','Ras Al Khaimah','Sharjah','Umm Al Quwain'],
    };
  },
  setup() {
    const store = useStore();
    let isActive = false;
    let search = ref<string>("");
    let customer = ref<number>(0);
    let quotesBodyParam = {} as QuotesBodyParam;
    QuotesService.getCountries();
    // const sendQuote = (quote_id) => {
    //   QuotesService.sendQuote({"quote_id":quote_id});
    // }
    onMounted(() => {
      //
    });
    const getDriverDetailsList = (customer_id, lead_id = -1) => {
      quotesBodyParam.customer_id = customer_id;
      quotesBodyParam.lead_id = lead_id;
      customer.value = customer_id;
      QuotesService.getDriverDetailsList(quotesBodyParam);
    };
    const searchCustomer = () => {
      CustomerService.searchCustomer({ keyword: search.value });
    };
    return {
      getDriverDetailsList,
      isActive,
      search,
      quotesBodyParam,
      customer,
      searchCustomer,
    };
  },
});
